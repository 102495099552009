.Login {
  max-width: 400px;
  margin: auto;
  display: flex;
  flex-direction: column;
  animation: fade-in 0.3s ease-in-out;
}

.Login input {
  margin: 8px 16px;
  padding: 12px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 12px;
  border: none;
  color: black;
  background-color: white;
}

.Login input:focus {
  outline: none;
  box-shadow: 0px 0px 0px 3px #0F4F4F;
}

.Login input:disabled {
  color: #505050;
  background-color: #D0D0D0;
}

.Login .submit {
  background-color: #F0F0F0;
  cursor: pointer;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
