.Stats {
  padding: 32px 16px 1px 16px;
  margin: 16px 0;
  background-color: var(--color-main-contrast);
  border-radius: 30px;
}

.Stats div {
  border-top: 5px solid lightgray;
  margin: 8px;
}

.Stats div label {
  display: inline-block;
  position: relative;
  top: -20px;
  padding: 8px;
  font-weight: bold;
  background-color: var(--color-main);
  border-radius: 100px;
  text-transform: uppercase;
}

.Stats div span {
  margin: -10px 0px 42px calc(100% - 180px);
  display: block;
  font-weight: bold;
  font-size: x-large;
  color: var(--color-text);
}

.Frequency {
  display: flex;
  align-items: center;
  border-top: none !important;
  margin-left: 0 !important;
}

.CheckIcon {
  height: 30px;
  fill: green;
  margin-right: 8px;
}

.CloseIcon {
  height: 30px;
  fill: brown;
  margin-right: 8px;
}
