.UserStats {
  max-width: 500px;
  margin: auto;
  padding: 0 16px 16px 16px;
  animation: fade-in 0.3s ease-in-out;
}

.Selectors {
  max-width: 300px;
  margin: auto;
}

.UserStatsAlternate {
  animation: fade-in 0.3s ease-in-out;
  padding: 0 16px 16px 16px;
}

.UserStatsAlternateUserName {
  max-width: 500px;
  margin: auto;
}

.Grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.GridItem {
  width: calc((100% / 3) - 16px);
  min-width: 300px;
  max-width: 400px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
