.Loading {
  position: absolute;
  top: 30vh;
  left: 0;
  font-size: 80px;
  width: 100%;
  text-align: center;
  animation: fade 1s ease-in-out infinite;
}

@keyframes fade {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}
