.DosesCalendar {
  margin-top: 16px;
  padding: 0 16px 16px 16px;
  background-color: var(--color-main-contrast);
  border-radius: 30px;
}

.CalendarHeader {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.CalendarTitle {
  margin: 16px 0;
  color: var(--color-text);
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
}

.Calendar {
  width: 400px;
  max-width: 100%;
  margin: auto;
  line-height: 1.125em;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
  text-align: center;
}

.CalendarDayNotInInterval {
  padding: 0;
  background: none;
  font-size: 18px;
  border: none;
  line-height: 2em;
  color: lightgrey;
}

.CalendarDayWithoutDoses {
  padding: 0;
  background: none;
  font-size: 18px;
  border: none;
  line-height: 2em;
  color: var(--color-text);
}

.CalendarDayWithDoses {
  padding: 0;
  font-size: 20px;
  font-weight: bold;
  border: none;
  line-height: 1.5em;
  color: var(--color-main-contrast);
  background-color: var(--color-main);
  border-radius: 8px;
  /* clip-path: circle(); */
}

.Icon {
  height: 50px;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  fill: var(--color-icon);
  stroke: var(--color-icon);
  border-radius: 100%;
  cursor: pointer;
}

abbr[title] {
  text-decoration: none !important;
}
