.Header {
  padding: 16px;
  font-size: x-large;
  font-weight: bold;
  text-align: center;
}

.BackButton {
  position: absolute;
  top: 20px;
  left: max(calc(100vw / 2 - 250px), 10px);
  border: none;
  background-color: var(--color-main);
  color: var(--color-main-contrast);
  font-weight: bold;
  font-size: initial;
  cursor: pointer;
}

.SignOutButton {
  position: absolute;
  top: 20px;
  right: max(calc(100vw / 2 - 250px), 10px);
  border: none;
  background-color: var(--color-main);
  color: var(--color-main-contrast);
  font-weight: bold;
  font-size: initial;
  cursor: pointer;
}
