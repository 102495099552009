.UserList {
  list-style-type: none;
  max-width: 500px;
  margin: auto;
  padding: 0 16px 16px 16px;
  animation: fade-in 0.3s ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
