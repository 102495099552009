.User {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  background-color: var(--color-main-contrast);
  border-radius: 1000px;
}

.UserIcon {
  height: 60px;
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  fill: var(--color-icon);
  transform: scale(1.25);
}

.UserInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 56px 0 16px;
}

.UserName {
  font-size: 18px;
  font-weight: bold;
  color: var(--color-text);
  text-transform: uppercase;
}

.UserId {
  margin: 5px 0;
  font-weight: bold;
  color: var(--color-main);
}
