.DoseListPage {
  list-style-type: none;
  margin: auto;
  padding: 0;
}

.DoseItem {
  margin-bottom: 20px;
  padding: 8px 16px;
  font-size: large;
  font-weight: bold;
  color: var(--color-text);
  background-color: var(--color-text-contrast);
  border-radius: 100px;
  animation: fade-in 0.6s ease-in-out;
  text-align: center;
}
