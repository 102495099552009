.Selector {
  margin-top: 16px;
  display: flex;
  align-items: center;
  background-color: var(--color-main-contrast);
  border-radius: 100px;
}

.InputsContainer {
  display: flex;
  flex-direction: column;
}

.Input {
  margin: 0 16px;
  border: none;
  font-size: large;
  font-weight: bold;
  color: var(--color-text);
  text-transform: uppercase;
  background-color: transparent;
}

.Icon {
  height: 50px;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  fill: var(--color-icon);
  stroke: var(--color-icon);
  border: 4px var(--color-icon) solid;
  border-radius: 100%;
}

.Icon path,
.Icon rect {
  stroke: var(--color-icon);
}

hr {
  width: 100%;
  margin: 2px;
}
