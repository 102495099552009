.Selector {
  background-color: var(--color-main-contrast);
  border-radius: 30px;
  cursor: pointer;
}

.Icon {
  height: 50px;
  width: 50px;
  max-width: 50px;
  min-width: 50px;
  fill: var(--color-icon);
  transform: scale(1.025);
  border: 4px transparent solid;
}

.IconSelected {
  height: 50px;
  width: 50px;
  max-width: 50px;
  min-width: 50px;
  fill: var(--color-icon);
  border: 4px var(--color-icon) solid;
  border-radius: 100%;
  transform: scale(1.025);
}

.Product {
  display: flex;
  align-items: center;
}

.ProductLabel {
  margin: 16px;
  font-size: large;
  font-weight: bold;
  color: var(--color-text);
  width: 100%;
}

.ProductOption {
  display: flex;
  align-items: center;
  border-top: 1px solid lightgray;
  margin: 4px 0;
}
