@import-normalize;

:root {

  font-family: system-ui, sans-serif;
  background-color: var(--color-main);
  color: var(--color-main-contrast);
  user-select: none;

  --color-main: #3ac3db;
  --color-main-contrast: #ffffff;
  --color-text: #2F4F4F;
  --color-text-contrast: #ffffff;
  --color-success: #10dc60;
  --color-warning: #ffce00;
  --color-danger: #f04141;
  --color-icon: #708090;

}

a {
  text-decoration: none;
}
